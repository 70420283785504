import React, { useEffect, useState } from "react";

// TODO: Make it better. "navigator.onLine" is not reliable (false positives)
// It would be better manual checks: https://jfhr.me/how-to-really-know-if-your-webapp-is-online/
const NoInternetBanner = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    // Add event listeners to check the online status when the component mounts
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  if (!isOnline) {
    return (
      <div
        style={{
          background: "#FCF0F0",
          color: "black",
          textAlign: "center",
          position: "fixed",
          top: 0,
          left: "10%",
          right: "10%",
          width: "80%",
          fontSize: "14px",
          zIndex: 9999,
        }}
      >
        No internet connection. Please check your network settings.
      </div>
    );
  }

  return null;
};

export default NoInternetBanner;
