/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode } from "react";

import styles from "./customFormStyles.module.scss";

type Props = {
  children: ReactNode;
  innerRef: any;
  innerProps: any;
};

const CustomMenu: FC<Props> = ({ innerProps, children }) => {
  return (
    <div {...innerProps} className={`${styles.customMenu}`}>
      {children}
    </div>
  );
};

export default CustomMenu;
