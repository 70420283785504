import moment, { Moment } from "moment-timezone";

export const getSelectedMomentFromSelectedDate = (date: Date): Moment => {
  const selectedMoment = moment(date);

  return moment()
    .year(selectedMoment.year())
    .month(selectedMoment.month())
    .date(selectedMoment.date());
};
