import * as Sentry from "@sentry/react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const captureError = (error: any): void => {
  Sentry.captureException(error);
  console.error(error);
};

export const captureMessage = (message: string): void => {
  Sentry.captureMessage(message);
  console.error(message);
};
