import moment from "moment-timezone";

import { MINIMUM_MINUTES } from "@/zustand/intervalGraphColoring";

const MIN_IN_HOUR = 60;

export const calculateOrderSize = (
  startDate: string,
  stopDate: string,
  elementWidth: number,
  minimum?: boolean,
): number => {
  const durationInMinutes = moment(stopDate)
    .utc()
    .diff(moment(startDate).utc(), "minutes");

  let finalMinutes = durationInMinutes;
  if (minimum && durationInMinutes < MINIMUM_MINUTES) {
    finalMinutes = MINIMUM_MINUTES;
  }
  const pxPerMinute = Math.floor(elementWidth * 10) / MIN_IN_HOUR / 10;
  const total = (finalMinutes * Math.round(pxPerMinute * 100)) / 100;
  return total;
};
