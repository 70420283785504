import React, { FC, ReactElement } from "react";
import Popup from "reactjs-popup";
import type { PopupPosition } from "reactjs-popup/dist/types";

type Props = {
  content: ReactElement;
  trigger: ReactElement;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  position?: PopupPosition;
  nested?: boolean;
  onOpen?: () => void;
  // onHover?: boolean;
};

const PopUp: FC<Props> = ({
  content,
  trigger,
  className = "",
  isOpen = false,
  nested = false,
  onClose = () => null,
  position = "bottom center",
  onOpen = () => null,
  // onHover,
}) => (
  <Popup
    trigger={trigger}
    position={position}
    closeOnDocumentClick
    className={className}
    nested={nested}
    open={isOpen}
    onClose={onClose}
    onOpen={onOpen}
    arrow={false}
    // on={onHover ? ["hover", "focus"] : ["click"]}
  >
    {content}
  </Popup>
);

export default PopUp;
