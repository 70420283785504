import { gql } from "@apollo/client";

export const UPDATE_VESSEL_PO_NUMBER = gql`
  mutation updateVesselOrderPurchaseOrderId(
    $facilityId: String!
    $issuedBy: String!
    $purchaseOrderId: String!
    $rentalAgreementId: String!
    $rentalAgreementLineId: String!
  ) {
    updateVesselOrderPurchaseOrderId(
      facilityId: $facilityId
      issuedBy: $issuedBy
      purchaseOrderId: $purchaseOrderId
      rentalAgreementId: $rentalAgreementId
      rentalAgreementLineId: $rentalAgreementLineId
    )
  }
`;

export const UPDATE_WORKORDER_PO_NUMBER = gql`
  mutation updateWorkOrderPurchaseOrderId(
    $facilityId: String!
    $issuedBy: String!
    $purchaseOrderId: String!
    $orderNumber: String!
    $orderLineNumber: String!
  ) {
    updateWorkOrderPurchaseOrderId(
      facilityId: $facilityId
      issuedBy: $issuedBy
      purchaseOrderId: $purchaseOrderId
      orderNumber: $orderNumber
      orderLineNumber: $orderLineNumber
    )
  }
`;
