import { Moment } from "moment-timezone";

export const validateStartBeforeStop = (
  startDate: Moment | null,
  stopDate: Moment | null,
) => {
  if (startDate && stopDate && startDate.isBefore(stopDate)) {
    return true;
  }
  return false;
};
