import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type SnackbarData = {
  isOpen: boolean;
  message: string;
  isButtonVisible?: boolean;
  type?: string;
};
type SetSnackFn = (
  isOpen: SnackbarData["isOpen"],
  message: SnackbarData["message"],
  isButtonVisible?: SnackbarData["isButtonVisible"],
  type?: SnackbarData["type"],
) => void;

type State = {
  snackbarData: SnackbarData;
  setSnack: SetSnackFn;
};

export const useSnackbar = create<State>()(
  subscribeWithSelector((set) => ({
    snackbarData: {
      isOpen: false,
      message: "",
      isButtonVisible: false,
      type: "",
    },
    setSnack: (isOpen, message, isButtonVisible, type) => {
      set({
        snackbarData: {
          isOpen,
          message,
          isButtonVisible: isButtonVisible || false,
          type: type || "",
        },
      });
    },
  })),
);
