import React from "react";
import { useDragLayer } from "react-dnd";
import ReactDOM from "react-dom";

import { Tooltip } from "./tooltip";

export const TimelineDraggingTooltip: React.FC = () => {
  const { isDragging, itemType } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));
  if (itemType === "draggable-side") {
    return null;
  }
  return ReactDOM.createPortal(
    <Tooltip
      id="draggingTooltip"
      isVisible={isDragging}
      position={{ vertical: "top" }}
      style={{
        left: "-10px",
        top: "-10px",
      }}
    />,
    document.body,
  );
};
