/* eslint-disable @typescript-eslint/no-explicit-any */
export const getPlainObjectHash = <T = Record<string, any> | any[] | any>(
  // object: Record<string, any> | any[] | any,
  object: T,
  options: { level?: number; maxLevel: number },
): string => {
  const { level = 1, maxLevel } = options;

  let result = "";

  if (!object || typeof object !== "object" || level > maxLevel) {
    return String(object);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    result += `${key}=`;

    if (Array.isArray(value)) {
      const stringifiedArray = value
        .map((item: any) =>
          getPlainObjectHash(item, { ...options, level: level + 1 }),
        )
        .join("");

      result += `[${stringifiedArray}];`;
    } else if (value && typeof value === "object") {
      const stringifiedObject = getPlainObjectHash(value, {
        ...options,
        level: level + 1,
      });

      result += `${key}={${stringifiedObject}};`;
    } else {
      result += String(value);
    }
  }

  return result;
};
