import { gql } from "@apollo/client";

export const UPDATE_WO_MUTATION = gql`
  mutation updateWorkOrderPlannedStartStopDates(
    $facilityId: String!
    $issuedBy: String!
    $startDate: DateTime!
    $stopDate: DateTime!
    $workOrderId: String!
  ) {
    updateWorkOrderPlannedStartStopDates(
      facilityId: $facilityId
      issuedBy: $issuedBy
      startDate: $startDate
      stopDate: $stopDate
      workOrderId: $workOrderId
    )
  }
`;
