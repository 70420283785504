import React, { FC, memo } from "react";

const SettingsSVG: FC = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1401 11.4404C17.1801 11.1404 17.2001 10.8304 17.2001 10.5004C17.2001 10.1804 17.1801 9.86039 17.1301 9.56039L19.1601 7.98039C19.3401 7.84039 19.3901 7.57039 19.2801 7.37039L17.3601 4.05039C17.2401 3.83039 16.9901 3.76039 16.7701 3.83039L14.3801 4.79039C13.8801 4.41039 13.3501 4.09039 12.7601 3.85039L12.4001 1.31039C12.3601 1.07039 12.1601 0.900391 11.9201 0.900391H8.08011C7.84011 0.900391 7.65011 1.07039 7.61011 1.31039L7.25011 3.85039C6.66011 4.09039 6.12011 4.42039 5.63011 4.79039L3.24011 3.83039C3.02011 3.75039 2.77011 3.83039 2.65011 4.05039L0.74011 7.37039C0.62011 7.58039 0.66011 7.84039 0.86011 7.98039L2.89011 9.56039C2.84011 9.86039 2.80011 10.1904 2.80011 10.5004C2.80011 10.8104 2.82011 11.1404 2.87011 11.4404L0.84011 13.0204C0.66011 13.1604 0.61011 13.4304 0.72011 13.6304L2.64011 16.9504C2.76011 17.1704 3.01011 17.2404 3.23011 17.1704L5.62011 16.2104C6.12011 16.5904 6.65011 16.9104 7.24011 17.1504L7.60011 19.6904C7.65011 19.9304 7.84011 20.1004 8.08011 20.1004H11.9201C12.1601 20.1004 12.3601 19.9304 12.3901 19.6904L12.7501 17.1504C13.3401 16.9104 13.8801 16.5904 14.3701 16.2104L16.7601 17.1704C16.9801 17.2504 17.2301 17.1704 17.3501 16.9504L19.2701 13.6304C19.3901 13.4104 19.3401 13.1604 19.1501 13.0204L17.1401 11.4404ZM10.0001 14.1004C8.02011 14.1004 6.40011 12.4804 6.40011 10.5004C6.40011 8.52039 8.02011 6.90039 10.0001 6.90039C11.9801 6.90039 13.6001 8.52039 13.6001 10.5004C13.6001 12.4804 11.9801 14.1004 10.0001 14.1004Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};
export default memo(SettingsSVG);
