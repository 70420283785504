import React, { PropsWithChildren, useMemo } from "react";

import styles from "./tooltip.module.scss";

type VerticalPosition = "top" | "bottom" | "center";

type Props = {
  id?: string;
  isVisible: boolean;
  position: {
    vertical: VerticalPosition;
  };
  style: React.CSSProperties;
};

const getVerticalAlignmentClassName = (position: VerticalPosition): string => {
  switch (position) {
    case "center":
      return styles.verticallyCenter;

    default:
      return styles[position];
  }
};

export const Tooltip: React.FC<PropsWithChildren<Props>> = ({
  id = "tooltip",
  position,
  isVisible,
  children = null,
  style,
}) => {
  const cssClasses = useMemo(() => {
    const verticalCssClass = getVerticalAlignmentClassName(position.vertical);

    return [styles.root, verticalCssClass, isVisible && styles.visible]
      .filter(Boolean)
      .join(" ");
  }, [position.vertical, isVisible]);

  return (
    <div className={cssClasses} style={style} id={id}>
      {children}
    </div>
  );
};
