import React, { useMemo } from "react";
import ReactDOM from "react-dom";

import { Tooltip } from "../tooltip";

type Props = {
  element: HTMLElement;
  time: string;
};

export const DraggableSideTimeTooltip: React.FC<Props> = ({
  element,
  time,
}) => {
  const targetElementPosition = useMemo(() => {
    if (!element) {
      return null;
    }

    return element.getBoundingClientRect();
  }, [element]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(
    <Tooltip
      isVisible={Boolean(element)}
      position={{ vertical: "top" }}
      style={{
        left: targetElementPosition?.left,
        top: targetElementPosition?.top,
      }}
    >
      {time}
    </Tooltip>,
    document.body,
  );
};
