/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Configuration, LogLevel } from "@azure/msal-browser";

declare global {
  interface Window {
    PB_AUTH_CLIENT_ID: string;
    PB_AUTHORITY_LINK: string;
    PB_REDIRECT_URI: string;
  }
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: window.PB_AUTH_CLIENT_ID,
    authority: window.PB_AUTHORITY_LINK,
    redirectUri: window.PB_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // Sometimes the popup doesn't close. Setting pollIntervalMilliseconds to 10 helps. https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5351#issuecomment-1306045973
    pollIntervalMilliseconds: 10,
    loggerOptions: {
      logLevel:
        // Enable extra verbosity with this query parameter: /dashboard?DEBUG_AUTH=4
        parseInt(
          new URLSearchParams(window.location.search)?.get("DEBUG_AUTH") || "1",
          10,
        ) ?? 1,
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.debug(`error: ${message}`);
            return;
          case LogLevel.Info:
            console.debug(`info: ${message}`);
            return;
          case LogLevel.Verbose:
            console.debug(`verbose: ${message}`);
            return;
          case LogLevel.Warning:
            console.debug(`warn: ${message}`);
            return;
          default:
            console.warn(`default: ${message}`);
        }
      },
    },
  },
};
